<template>
  <div>
    <div class="home-page__header">
      <div class="home-page__header__title _font-weight__600 _font-size__heading1">
        Tower Info Management
      </div>
      <div class="home-page__header__operate _flex _flex-justify__between">
        <!-- 这里需要对元素宽度固定，否则进行动画时元素宽度会自适应 -->
        <div class="home-page__header__operate__form-box _flex">
          <el-button type="primary" @click="handleCreate"> Add New Tower </el-button>
          <el-input
            class="input-rem"
            v-model="searchForm.condition"
            placeholder="Search Tower by ID or Name"
          />
          <el-button type="info" @click="handleSearch"> Search </el-button>
          <el-select v-model="searchForm.selectValue" clearable placeholder="All Locations">
            <el-option
              v-for="item in options"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <list-view
      :list="towerResult.rows"
      :total="Number(towerResult.total)"
      @edit="handleEdit"
      @refresh="getList"
    />
    <tower-form ref="towerFormRef" @success="getList()"></tower-form>
  </div>
</template>

<script>
import { optionsFun } from '@/utils'
import { locationList } from '@/api/home'
import { findPage } from '@/api/tower-manage'

const defaultQuery = {
  selectValue: '',
  condition: '',
  orderBy: '',
  pageNo: 1,
  pageSize: 10,
  sort: ''
}
export default {
  data() {
    return {
      options: [],
      // 电塔信息
      towerResult: {
        rows: [],
        total: 0
      },
      searchForm: { ...defaultQuery }
    }
  },

  components: {
    ListView: () => import('./components/list-view'),
    TowerForm: () => import('./components/tower-form')
  },

  mounted() {
    this.getList()
    locationList().then((res) => {
      this.options = optionsFun(res.result)
    })
  },

  methods: {
    getList(e) {
      // 获取电塔
      findPage({ ...this.searchForm, pageNo: e || 1, location: this.searchForm.selectValue }).then(
        (res) => {
          this.towerResult = res.result
        }
      )
    },

    handleSearch() {
      this.getList()
    },

    handleCreate() {
      console.log('handleCreate')
      this.$refs.towerFormRef.open()
    },
    handleEdit(row) {
      console.log('handleEdit')
      this.$refs.towerFormRef.open(row)
    }
  }
}
</script>

<style lang="scss" scoped>
.home-page {
  &__header {
    &__title {
      margin-bottom: 20px;
    }
    &__operate {
      width: var(--main-width);
      margin-bottom: 35px;
      .input-rem {
        height: 36px;
        line-height: 36px;
      }

      &__form-box {
        & > * {
          margin-right: 20px;
          line-height: 36px;

          ::v-deep .el-input__inner {
            height: 100%;
          }
        }
        & > ::v-deep .el-input {
          width: 325px;
          .el-input__inner {
            border-color: #979797;
            color: var(--color-black);
            &::placeholder {
              color: #979797;
            }
          }
        }

        & .el-button {
          color: var(--color-white);
          text-align: center;
          padding: 0 10px !important;
        }

        & > ::v-deep .el-select {
          width: 160px;

          .el-input {
            height: 100%;
          }
          .el-input__inner {
            color: var(--color-black);
            border: none;
            border-bottom: 2px solid var(--color-info);
            border-radius: 0px;
            background: rgba(0, 0, 0, 0);
            padding: 0;
            &::placeholder {
              color: var(--color-black);
            }
          }
          & .el-select__caret.el-icon-arrow-up::before {
            content: '\e78f';
            color: var(--color-black);
          }

          .el-input__icon {
            line-height: 1;
          }
        }
      }

      &__buttons {
        transition: all 0.3s ease;

        .el-button {
          padding: 0px 15px;
        }
      }
    }
  }
}
</style>
